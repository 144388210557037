import React, { useEffect, useState, useCallback, useRef } from 'react';
import styles from './styles/Details.module.css';
import { MainContent } from 'components/sidebar';
import {
  Modal,
  Box,
  Typography,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  useLocation,
  useNavigate,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import organizationAPI from 'api/api';

import LoadingOverlay from 'components/LoadingOverlay';

import {
  MultipleSelectDocument,
  SelectAssementStatus,
  SelectQuestionFormatTemplate,
  SelectTimeAllowed,
} from 'components/SelectField';

import {
  ChevronRight,
  Comment,
  CopyAll,
  Delete,
  Download,
  Remove,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';

import { DateRangePicker } from 'rsuite';
import { CustomTextField } from 'components/TextField';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import { CustomAutoComplete } from 'components/AutoComplete';

const CommentModal = ({
  comment,
  user,
  open,
  setOpen,
  setLoading,
  assessmentId,
  getAssessment,
}) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [reply, setReply] = useState('');

  const convertDate = (dateString) => {
    return moment(dateString).format('MMM Do, YYYY');
  };

  const handleAddComment = async () => {
    setLoading(true);
    const data = {
      userId: user._id,
      name: user.firstName + ' ' + user.lastName,
      message: reply,
      role: user.role,
      avatar: user.avatar,
    };

    const response = await organizationAPI.addCommentToAssessment(
      data,
      assessmentId
    );
    await getAssessment();
    setReply('');
    setLoading(false);
  };

  const handleReply = () => {
    handleAddComment();
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title">
          <div className={styles.modal_comment_header}>
            <p>Comments</p>
            <span>COLLAPSE COMMENTS</span>
          </div>
          <div className={styles.modal_border}></div>
        </Typography>
        <div className={styles.modal_comment_section}>
          {comment.map((item, index) => (
            <div className={styles.modal_body} key={index}>
              <div className={styles.post_body_head}>
                <div className={styles.post_body_image}>
                  {item.avatar && <img src={item.avatar} alt="" />}
                  <div className={styles.post_body_head_left}>
                    <div className={styles.profile}>
                      <h4>{item.name}</h4>
                    </div>
                    {item.role && (
                      <div className={styles.role}>
                        <span>{item.role.toUpperCase()}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.post_date}>
                  <p>{convertDate(item.date)}</p>
                </div>
              </div>
              <p>{item.message}</p>
            </div>
          ))}
        </div>
        <div className={styles.bottom_section}>
          <CustomTextField
            label={`Reply`}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            multiline
            className={styles.reply_field}
          />

          <div className={styles.modal_bottom}>
            <button
              disabled={!reply}
              className={styles.reply}
              onClick={handleReply}
            >
              Post Reply
            </button>
            <button className={styles.collapse} onClick={() => setOpen(false)}>
              Collapse Comments
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

// Publish Section
const RemoveAssessmentPublishModal = ({
  assessmentId,
  publish,
  setPublish,
  open,
  setOpen,
  setLoading,
  getAssessment,
  getCourseRegionSchools,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteAssessmentPublish(
        assessmentId,
        publish._id
      );
      await getAssessment();
      await getCourseRegionSchools();
      setPublish(null);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Publish</h5>
          <p>
            Are you sure you want to delete this publish? This action cannot be
            undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const RemoveAssessmentIndicatorModal = ({
  assessment,
  open,
  setOpen,
  setLoading,
  indicatorList,
  setIndicatorList,
  removeSelectedIndicatorIndex,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    const temp = [...indicatorList];
    temp.splice(removeSelectedIndicatorIndex, 1);
    setIndicatorList(temp);
    const data = {
      indicators: temp,
    };
    try {
      const response = await organizationAPI.updateAssessment(
        assessment._id,
        data
      );
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Indicator</h5>
          <p>
            Are you sure you want to delete this indicator? This action cannot
            be undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const RemoveAssessmentScoringBracketModal = ({
  open,
  setLoading,
  setOpen,
  sections,
  setSections,
  removeSelectedScoringBracketIndex,
  removeSelectedSectionIndex,
  updateAssessmentSection,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    const temp = [...sections];
    temp[removeSelectedSectionIndex].brackets.splice(
      removeSelectedScoringBracketIndex,
      1
    );

    setSections(temp);

    updateAssessmentSection(
      e,
      sections[removeSelectedSectionIndex]._id,
      temp[removeSelectedSectionIndex].brackets
    );
    setLoading(false);

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Scoring</h5>
          <p>
            Are you sure you want to delete this scoring bracket? This action
            cannot be undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const RemoveAssessmentTotalScoringBracketModal = ({
  assessment,
  open,
  setLoading,
  setOpen,
  removeSelectedTotalScoringBracketIndex,
  updateAssessmentTotalScoring,
  totalScoringBrackets,
  setTotalScoringBrackets,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);

    const temp = [...assessment.totalScoringBrackets];
    temp.splice(removeSelectedTotalScoringBracketIndex, 1);

    updateAssessmentTotalScoring(e, temp);

    setLoading(false);

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Scoring</h5>
          <p>
            Are you sure you want to delete this scoring bracket? This action
            cannot be undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const PublishSection = ({ assessment, setLoading, getAssessment }) => {
  const [regions, setRegions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [schools, setSchools] = useState([]);
  const [newAudience, setNewAudience] = useState(false);
  const endOfPublishRef = useRef(null);

  const [publish, setPublish] = useState(null);

  const [newRegion, setNewRegion] = useState('');
  const [newCourse, setNewCourse] = useState('');
  const [newSchool, setNewSchool] = useState('');

  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [deletePublish, setDeletePublish] = useState(null);

  const getCourseRegionSchools = async () => {
    setLoading(true);
    try {
      const response =
        await organizationAPI.getOrganizationCourseRegionSchools();

      const assessmentResponse = await organizationAPI.getAssessmentId(
        assessment._id
      );

      const region = response.data.filter((item) => item.type === 'region');
      const course = response.data.filter((item) => item.type === 'courses');
      const school = response.data.filter((item) => item.type === 'school');

      setCourses(course);
      setSchools(school);
      setRegions(region);

      if (assessmentResponse.data.publish.length > 0) {
        // map through the publish array and set the selected regions, courses and schools

        const tempSelectedRegions = [];
        const tempSelectedCourses = [];
        const tempSelectedSchools = [];

        assessmentResponse.data.publish.map((publish, index) => {
          const selectedRegion = region.find(
            (item) => item._id === publish.regionId
          );
          const selectedCourse = course.find(
            (item) => item._id === publish.courseId
          );
          const selectedSchool = school.find(
            (item) => item._id === publish.schoolId
          );

          tempSelectedRegions.push(selectedRegion);
          tempSelectedCourses.push(selectedCourse);
          tempSelectedSchools.push(selectedSchool);
        });

        setPublish({
          selectedRegions: tempSelectedRegions,
          selectedCourses: tempSelectedCourses,
          selectedSchools: tempSelectedSchools,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      regionId: newRegion._id,
      courseId: newCourse._id,
      schoolId: newSchool._id,
    };

    try {
      const response =
        await organizationAPI.publishRegionCourseSchoolAssessment(
          assessment._id,
          data
        );
      await getAssessment();
      await getCourseRegionSchools();
      setNewRegion('');
      setNewCourse('');
      setNewSchool('');
      setNewAudience(false);
    } catch (error) {
      console.log(error);
    }

    endOfPublishRef.current?.scrollIntoView({ behavior: 'smooth' });

    setLoading(false);
  };

  useEffect(() => {
    getCourseRegionSchools();
  }, []);

  return (
    <div className={styles.review_section}>
      <RemoveAssessmentPublishModal
        assessmentId={assessment._id}
        publish={deletePublish}
        setPublish={setDeletePublish}
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
        setLoading={setLoading}
        getAssessment={getAssessment}
        getCourseRegionSchools={getCourseRegionSchools}
      />
      <div className={styles.mcq_review_section}>
        <div className={styles.mcq_header}>
          <div className={styles.review_header}>
            <p>Invite Assessment Audience</p>
            <p></p>
          </div>

          {newAudience && (
            <div className={styles.question_button}>
              <p className={styles.save} onClick={handlePublish}>
                <Check /> PUBLISH
              </p>
            </div>
          )}
        </div>
        <div className={styles.border}></div>
        {publish &&
          publish.selectedRegions.map((region, index) => {
            return (
              <div className={styles.question_flag_section} key={index}>
                <div className={styles.input_tab}>
                  <div className={styles.question_flag}>
                    <CustomTextField
                      label="Region"
                      value={region.name}
                      readOnly
                      disabled
                      fullWidth
                    />
                    <Remove
                      onClick={() => {
                        setOpenRemoveModal(true);
                        setDeletePublish(assessment.publish[index]);
                      }}
                    />
                  </div>
                  <CustomTextField
                    label="Course"
                    value={publish.selectedCourses[index].name}
                    disabled
                    readOnly
                    fullWidth
                  />

                  <CustomTextField
                    label="School"
                    value={publish.selectedSchools[index].name}
                    disabled
                    readOnly
                    fullWidth
                  />
                </div>
              </div>
            );
          })}
        <div ref={endOfPublishRef}></div>

        {newAudience && (
          <>
            <CustomAutoComplete
              options={regions}
              getOptionLabel={(option) => option.name || ''}
              label="Region"
              multiple={false}
              placeholder="Select Regions, separated by semicolons (;)"
              value={newRegion}
              onChange={(e, value) => {
                setNewRegion(value);
              }}
            />

            <CustomAutoComplete
              options={courses}
              getOptionLabel={(option) => option.name || ''}
              label="Course"
              multiple={false}
              placeholder="Select Courses, separated by semicolons (;)"
              value={newCourse}
              onChange={(e, value) => {
                setNewCourse(value);
              }}
            />

            <CustomAutoComplete
              options={schools}
              getOptionLabel={(option) => option.name || ''}
              label="School"
              multiple={false}
              placeholder="Select Schools, separated by semicolons (;)"
              value={newSchool}
              onChange={(e, value) => {
                setNewSchool(value);
              }}
            />
          </>
        )}
        {!newAudience && (
          <div
            className={styles.new_question}
            onClick={() => setNewAudience(true)}
          >
            <Add />

            <p>Add Assessment Audience</p>
          </div>
        )}
      </div>
    </div>
  );
};
// Review Section
const ReviewSection = ({
  conditionSummary,
  getAssessment,
  getConditionsSummary,
  setLoading,
  assessment,
  setSearchParams,
}) => {
  const [sections, setSections] = useState(null);
  const [totalScoringBrackets, setTotalScoringBrackets] = useState(null);
  const [indicatorList, setIndicatorList] = useState([]);
  const [oldIndicatorList, setOldIndicatorList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [removeIndicatorModal, setRemoveIndicatorModal] = useState(false);
  const [removeSelectedIndicatorIndex, setRemoveSelectedIndicatorIndex] =
    useState(null);

  const [removeScoringBracketModal, setRemoveScoringBracketModal] =
    useState(false);

  const [removeSelectedSectionIndex, setRemoveSelectedSectionIndex] =
    useState(null);
  const [
    removeSelectedScoringBracketIndex,
    setRemoveSelectedScoringBracketIndex,
  ] = useState(null);

  const [removeTotalScoringBracketModal, setRemoveTotalScoringBracketModal] =
    useState(false);
  const [
    removeSelectedTotalScoringBracketIndex,
    setRemoveSelectedTotalScoringBracketIndex,
  ] = useState(null);

  useEffect(() => {
    if (assessment) {
      setSections(assessment.sections);
      setTotalScoringBrackets(assessment.totalScoringBrackets);
      setIndicatorList(assessment.indicators);
      setOldIndicatorList(assessment.indicators);
    }
  }, [assessment]);

  useEffect(() => {
    if (indicatorList !== oldIndicatorList) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [indicatorList]);

  const handleSaveIndicators = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      indicators: indicatorList,
    };
    try {
      const response = await organizationAPI.updateAssessment(
        assessment._id,
        data
      );
      setOldIndicatorList(indicatorList);
      getConditionsSummary();
      getAssessment();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const updateAssessmentSection = async (e, sectionId, brackets) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      brackets: brackets,
    };

    try {
      const response = await organizationAPI.updateAssessmentSection(
        assessment._id,
        sectionId,
        data
      );
      getAssessment();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const updateAssessmentTotalScoring = async (e, totalScoringBrackets) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      totalScoringBrackets: totalScoringBrackets,
    };

    try {
      const response = await organizationAPI.updateAssessment(
        assessment._id,
        data
      );
      getAssessment();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className={styles.review_section}>
      <RemoveAssessmentIndicatorModal
        assessment={assessment}
        open={removeIndicatorModal}
        setOpen={setRemoveIndicatorModal}
        setLoading={setLoading}
        indicatorList={indicatorList}
        setIndicatorList={setIndicatorList}
        removeSelectedIndicatorIndex={removeSelectedIndicatorIndex}
      />
      <RemoveAssessmentScoringBracketModal
        assessment={assessment}
        open={removeScoringBracketModal}
        setOpen={setRemoveScoringBracketModal}
        setLoading={setLoading}
        sections={sections}
        setSections={setSections}
        removeSelectedScoringBracketIndex={removeSelectedScoringBracketIndex}
        removeSelectedSectionIndex={removeSelectedSectionIndex}
        updateAssessmentSection={updateAssessmentSection}
      />
      <RemoveAssessmentTotalScoringBracketModal
        assessment={assessment}
        open={removeTotalScoringBracketModal}
        setOpen={setRemoveTotalScoringBracketModal}
        setLoading={setLoading}
        removeSelectedTotalScoringBracketIndex={
          removeSelectedTotalScoringBracketIndex
        }
        updateAssessmentTotalScoring={updateAssessmentTotalScoring}
        totalScoringBrackets={totalScoringBrackets}
        setTotalScoringBrackets={setTotalScoringBrackets}
      />

      <div className={styles.indicator_section}>
        {conditionSummary && conditionSummary.breakdown.length > 0 && (
          <div className={styles.summary_table}>
            <table className="styled_table">
              <thead className="table_headers">
                <tr>
                  <th>Section</th>

                  <th>Question</th>
                  <th>Topic</th>
                  <th>Max Score</th>
                </tr>
              </thead>
              <tbody>
                {conditionSummary.breakdown.map((summary, index) => (
                  <tr className={styles.coachrow} key={index}>
                    <td className={styles.data}>{summary.section}</td>
                    <td className={styles.data}>{summary.questionsRange}</td>
                    <td className={styles.data}>{summary.sectionTitle}</td>
                    <td className={styles.data}>{summary.maxScore}</td>
                  </tr>
                ))}

                {conditionSummary.totals && (
                  <tr className={styles.coachrow}>
                    <td className={styles.data}>
                      {conditionSummary.totals.sections}
                    </td>
                    <td className={styles.data}>
                      {conditionSummary.totals.questions}
                    </td>
                    <td className={styles.data}>Total Survey Score</td>
                    <td className={styles.data}>
                      {conditionSummary.totals.totalScore}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.question_flag_section}>
          <div className={styles.mcq_header}>
            <div className={styles.review_header}>
              <p>Indicators</p>
            </div>
            <div className={styles.question_button}>
              <p
                className={styles.save}
                onClick={handleSaveIndicators}
                disabled={buttonDisabled}
              >
                <Check /> SAVE
              </p>
            </div>
          </div>
          {indicatorList.map((conditions, index) => (
            <div className={styles.question_flag} key={index}>
              <CustomTextField
                label="INDICATOR"
                placeholder={'ENTER INDICATOR'}
                multiline
                value={conditions}
                onChange={(e) => {
                  const temp = [...indicatorList];
                  temp[index] = e.target.value;
                  setIndicatorList(temp);
                }}
                fullWidth
              />
              <Remove
                onClick={() => {
                  setRemoveSelectedIndicatorIndex(index);
                  setRemoveIndicatorModal(true);
                }}
              />
            </div>
          ))}
          <div className={styles.new_question_button}>
            <div
              className={styles.new_question}
              onClick={() => setIndicatorList([...indicatorList, ''])}
            >
              <Add />

              <p>Add New Indicator</p>
            </div>
          </div>
        </div>
      </div>

      {sections &&
        sections.length > 0 &&
        sections.map((section, index) => (
          <div className={styles.mcq_review_section} key={index}>
            <div className={styles.mcq_header}>
              <div className={styles.review_header}>
                <p>{section.title}</p>
                <p>{section.template}</p>
              </div>
              <div className={styles.question_button}>
                <p
                  className={styles.save}
                  onClick={(e) => {
                    updateAssessmentSection(
                      e,
                      sections[index]._id,
                      sections[index].brackets
                    );
                  }}
                >
                  <Check /> SAVE
                </p>
                <p
                  className={styles.rename}
                  onClick={() => {
                    setSearchParams({
                      tab: 'question',
                      sectionId: index,
                    });
                  }}
                >
                  <Edit /> EDIT
                </p>
              </div>
            </div>
            <div className={styles.border}></div>
            <h2 className={styles.review_title_h2}>Scoring Brackets</h2>
            <div className={styles.border}></div>
            <div className={styles.scoring_section}>
              {sections &&
                sections[index].brackets.length > 0 &&
                sections[index].brackets.map((bracket, bracketIndex) => (
                  <div
                    className={styles.scoring_bracket_section}
                    key={bracketIndex}
                  >
                    <div className={styles.scoring_bracket}>
                      <p>{`SCORE BRACKET ${index + 1}`}</p>
                      <CustomTextField
                        fullWidth
                        size="small"
                        value={bracket.title}
                        onChange={(e) => {
                          const temp = [...sections];
                          temp[index].brackets[bracketIndex].title =
                            e.target.value;
                          setSections(temp);
                        }}
                      />
                      <p>Low Point</p>
                      <CustomTextField
                        size="small"
                        value={bracket.min}
                        onChange={(e) => {
                          const temp = [...sections];
                          temp[index].brackets[bracketIndex].min =
                            e.target.value;
                          setSections(temp);
                        }}
                      />
                      <p>High Point</p>
                      <CustomTextField
                        size="small"
                        value={bracket.max}
                        onChange={(e) => {
                          const temp = [...sections];
                          temp[index].brackets[bracketIndex].max =
                            e.target.value;
                          setSections(temp);
                        }}
                      />
                      <Remove
                        onClick={() => {
                          setRemoveSelectedSectionIndex(index);
                          setRemoveSelectedScoringBracketIndex(bracketIndex);
                          setRemoveScoringBracketModal(true);
                        }}
                      />
                    </div>
                    <div className={styles.scoring_description}>
                      <CustomTextField
                        fullWidth
                        size="small"
                        value={bracket.description}
                        onChange={(e) => {
                          const temp = [...sections];
                          temp[index].brackets[bracketIndex].description =
                            e.target.value;
                          setSections(temp);
                        }}
                        multiline
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div
              className={styles.new_question}
              onClick={() => {
                const temp = [...sections];
                temp[index].brackets.push({
                  title: '',
                  min: '',
                  max: '',
                  description: '',
                });
                setSections(temp);
              }}
            >
              <Add />
              <p>Add New Scoring Bracket</p>
            </div>
            <div className={styles.review_section_title}>
              <h2 className={styles.review_title_h2}>Malingering Flags</h2>
            </div>
            <div className={styles.border}></div>
            <div className={styles.review_list}>
              <ul>
                {section.malingeringFlags.length > 0 &&
                  section.malingeringFlags.map((flag, index) => (
                    <li key={index}>{flag}</li>
                  ))}
              </ul>
            </div>

            <div className={styles.review_section_title}>
              <h2 className={styles.review_title_h2}>Other Conditions</h2>
            </div>
            <div className={styles.border}></div>
            <div className={styles.review_list}>
              <ul>
                {section.otherConditions.length > 0 &&
                  section.otherConditions.map((otherCondition, index) => (
                    <li key={index}>{otherCondition}</li>
                  ))}
              </ul>
            </div>
            {/* <h2 className={styles.review_title_h2}>
          1. I often set long-term goals for myself.
        </h2>

        <FormControl className={styles.mcq_radio}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value={'test'}
              control={<Radio size="small" />}
              label={'Strongly Agree'}
            />
            <FormControlLabel
              value={'test2'}
              control={<Radio size="small" />}
              label={'Agree'}
            />
            <FormControlLabel
              value={'test3'}
              control={<Radio size="small" />}
              label={'Disagree'}
            />
            <FormControlLabel
              value={'test4'}
              control={<Radio size="small" />}
              label={'Strongly Disagree'}
            />
          </RadioGroup>
        </FormControl>
        <h2 className={styles.review_title_h2}>
          2. I often set long-term goals for myself.
        </h2>

        <FormControl className={styles.mcq_radio}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value={'test'}
              control={<Radio size="small" />}
              label={'Strongly Agree'}
            />
            <FormControlLabel
              value={'test2'}
              control={<Radio size="small" />}
              label={'Agree'}
            />
            <FormControlLabel
              value={'test3'}
              control={<Radio size="small" />}
              label={'Disagree'}
            />
            <FormControlLabel
              value={'test4'}
              control={<Radio size="small" />}
              label={'Strongly Disagree'}
            />
          </RadioGroup>
        </FormControl> */}
          </div>
        ))}

      <div className={styles.total_scoring_section}>
        <div className={styles.mcq_header}>
          <div className={styles.review_header}>
            <p>TOTAL SCORING SECTION</p>
          </div>
          <div className={styles.question_button}>
            <p
              className={styles.save}
              onClick={(e) => {
                updateAssessmentTotalScoring(e, totalScoringBrackets);
              }}
            >
              <Check /> SAVE
            </p>
          </div>
        </div>
        <div className={styles.border}></div>
        <h2 className={styles.review_title_h2}>Total Scoring Brackets</h2>
        <div className={styles.border}></div>
        {totalScoringBrackets &&
          totalScoringBrackets.length > 0 &&
          totalScoringBrackets.map((totalScore, bracketIndex) => (
            <div className={styles.scoring_section} key={bracketIndex}>
              <div className={styles.scoring_bracket_section}>
                <div className={styles.scoring_bracket}>
                  <p>TOTAL SCORES</p>
                  <CustomTextField
                    fullWidth
                    size="small"
                    value={totalScore.title}
                    onChange={(e) => {
                      const temp = [...totalScoringBrackets];
                      temp[bracketIndex].title = e.target.value;
                      setTotalScoringBrackets(temp);
                    }}
                  />
                  <p>Low Point</p>
                  <CustomTextField
                    size="small"
                    value={totalScore.min}
                    onChange={(e) => {
                      const temp = [...totalScoringBrackets];
                      temp[bracketIndex].min = e.target.value;
                      setTotalScoringBrackets(temp);
                    }}
                  />
                  <p>High Point</p>
                  <CustomTextField
                    size="small"
                    value={totalScore.max}
                    onChange={(e) => {
                      const temp = [...totalScoringBrackets];
                      temp[bracketIndex].max = e.target.value;
                      setTotalScoringBrackets(temp);
                    }}
                  />
                  <Remove
                    onClick={() => {
                      setRemoveSelectedTotalScoringBracketIndex(bracketIndex);
                      setRemoveTotalScoringBracketModal(true);
                    }}
                  />
                </div>
                <div className={styles.scoring_description}>
                  <CustomTextField
                    fullWidth
                    size="small"
                    value={totalScore.description}
                    onChange={(e) => {
                      const temp = [...totalScoringBrackets];
                      temp[bracketIndex].description = e.target.value;
                      setTotalScoringBrackets(temp);
                    }}
                    multiline
                  />
                </div>
              </div>
            </div>
          ))}

        <div
          className={styles.new_question}
          onClick={() => {
            const temp = [...totalScoringBrackets];
            temp.push({
              title: '',
              min: '',
              max: '',
              description: '',
            });
            setTotalScoringBrackets(temp);
          }}
        >
          <Add />
          <p>Add New Total Score Bracket</p>
        </div>
      </div>
    </div>
  );
};

// Question Section

const RemoveQuestionModal = ({
  assessmentId,
  sectionId,
  questionId,
  open,
  setOpen,
  setLoading,
  getAssessment,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteAssessmentQuestion(
        assessmentId,
        sectionId,
        questionId
      );
      await getAssessment();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Question</h5>
          <p>
            Are you sure you want to delete this question? This action cannot be
            undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const RemoveSectionModal = ({
  assessmentId,
  sectionId,
  open,
  setOpen,
  setLoading,
  getAssessment,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteAssessmentSection(
        assessmentId,
        sectionId
      );
      await getAssessment();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Section</h5>
          <p>
            Are you sure you want to delete this section? This action cannot be
            undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const EditMCQQuestionSection = ({
  assessmentId,
  section,
  setLoading,
  getAssessment,
  questionData,
  edit,
  setEdit,
}) => {
  const [disableQuestionSaveButton, setDisableQuestionSaveButton] =
    useState(true);
  const [question, setQuestion] = useState({
    question: questionData.question,
    options: questionData.options,
    answer: questionData.options.indexOf(questionData.answer),
    tipText: questionData.tipText,
    tipActivationThreshold: questionData.tipActivationThreshold,
  });

  const handleQuestionCheck = (e, index) => {
    setQuestion({ ...question, answer: index });
  };

  const handleQuestionSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      question: question.question,
      options: question.options,
      answer: question.options[question.answer],
    };

    if (question.tipText) {
      data.tipText = question.tipText;
    }

    if (question.tipActivationThreshold) {
      data.tipActivationThreshold = parseInt(question.tipActivationThreshold);
    }

    try {
      const response = await organizationAPI.updateAssessmentQuestion(
        assessmentId,
        section._id,
        questionData._id,
        data
      );
      await getAssessment();
      setQuestion({
        question: '',
        options: ['', '', '', ''],
        answer: '',
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setEdit(false);
    }
    setLoading(false);
    setEdit(false);
  };

  useEffect(() => {
    if (
      question.question &&
      question.options.every((option) => option.length > 0)
    ) {
      setDisableQuestionSaveButton(false);
    } else {
      setDisableQuestionSaveButton(true);
    }
  }, [question]);

  return (
    <>
      {edit !== false && (
        <>
          <div className={styles.question_form}>
            <div className={styles.mcq_header}>
              <p>Question 1</p>
              <div className={styles.question_button}>
                <button
                  className={styles.save}
                  onClick={handleQuestionSave}
                  disabled={disableQuestionSaveButton}
                >
                  <Check /> SAVE
                </button>
                <button
                  className={styles.rename}
                  onClick={() => {
                    setEdit(false);
                    setQuestion({
                      question: '',
                      options: ['', '', '', ''],
                      answer: '',
                    });
                  }}
                >
                  <Close /> DISCARD
                </button>
              </div>
            </div>
            <CustomTextField
              label="QUESTION"
              placeholder={'ENTER QUESTION'}
              fullWidth
              value={question.question}
              onChange={(e) =>
                setQuestion({ ...question, question: e.target.value })
              }
            />

            <div className={styles.border}></div>

            <div className={styles.mcq_section}>
              {question.options.map((option, index) => (
                <div key={index} className={styles.mcq_question_field}>
                  <p>Option {index + 1}</p>
                  <CustomTextField
                    label={`OPTION ${index + 1}`}
                    placeholder={`ENTER OPTION ${index + 1}`}
                    fullWidth
                    value={question.options[index]}
                    onChange={(e) => {
                      const temp = [...question.options];
                      temp[index] = e.target.value;
                      setQuestion({ ...question, options: temp });
                    }}
                  />
                  <Checkbox
                    checked={question.answer === index}
                    onChange={(e) => handleQuestionCheck(e, index)}
                  />
                  <span>Correct</span>
                  <Remove
                    onClick={() => {
                      const temp = [...question.options];
                      temp.splice(index, 1);
                      setQuestion({ ...question, options: temp });
                    }}
                  />
                </div>
              ))}
            </div>

            <div
              style={{
                marginTop: '20px',
              }}
              className={styles.new_question}
              onClick={() => {
                setQuestion({
                  ...question,
                  options: [...question.options, ''],
                });
              }}
            >
              <Add />

              <p>Add New Option</p>
            </div>

            <div className={styles.tip_section}>
              <CustomTextField
                label="TIP"
                placeholder={
                  'Add a helpful tip that can help candidates with their self-improvement'
                }
                fullWidth
                value={question.tipText}
                onChange={(e) =>
                  setQuestion({ ...question, tipText: e.target.value })
                }
              />
              <CustomTextField
                label="SCORE THRESHOLD FOR TIP ACTIVATION"
                placeholder={'SCORE THRESHOLD FOR TIP ACTIVATION'}
                fullWidth
                value={question.tipActivationThreshold}
                onChange={(e) =>
                  setQuestion({
                    ...question,
                    tipActivationThreshold: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const MCQQuestionPreviewSection = ({
  assessmentId,
  section,
  item,
  index,
  setLoading,
  getAssessment,
}) => {
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleDuplicateQuestion = async (e, item) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      question: item.question,
      options: item.options,
      answer: item.answer,
    };

    if (item.tipText) {
      data.tipText = item.tipText;
    }

    if (item.tipActivationThreshold) {
      data.tipActivationThreshold = item.tipActivationThreshold;
    }

    try {
      const response = await organizationAPI.createAssessmentQuestion(
        assessmentId,
        section._id,
        data
      );
      await getAssessment();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className={styles.mcq_preview_section}>
      <RemoveQuestionModal
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
        setLoading={setLoading}
        assessmentId={assessmentId}
        sectionId={section._id}
        questionId={item._id}
        getAssessment={getAssessment}
      />
      {edit === index ? (
        <>
          <EditMCQQuestionSection
            assessmentId={assessmentId}
            section={section}
            setLoading={setLoading}
            getAssessment={getAssessment}
            questionData={item}
            edit={edit}
            setEdit={setEdit}
          />
        </>
      ) : (
        <div className={styles.mcq_preview}>
          <div className={styles.mcq_header}>
            <p>{`${index + 1} ${item.question}`}</p>
            <div className={styles.question_button}>
              <p
                className={styles.delete}
                onClick={() => {
                  setOpenRemoveModal(true);
                }}
              >
                <Delete /> DELETE
              </p>
              <p
                className={styles.rename}
                onClick={(e) => {
                  handleDuplicateQuestion(e, item);
                }}
              >
                <CopyAll /> DUPLICATE
              </p>
              <p className={styles.rename} onClick={() => setEdit(index)}>
                <Edit /> EDII
              </p>
            </div>
          </div>
          <FormControl className={styles.mcq_radio}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={item.answer}
            >
              {item.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio size="small" />}
                  label={option}
                  disabled
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </div>
  );
};

const MCQQuestionSection = ({
  assessmentId,
  section,
  setLoading,
  getAssessment,
}) => {
  const [newQuestion, setNewQuestion] = useState(false);
  const [disableQuestionSaveButton, setDisableQuestionSaveButton] =
    useState(true);
  const [question, setQuestion] = useState({
    question: '',
    options: ['', '', '', ''],
    answer: '',
    tipText: '',
    tipActivationThreshold: '',
  });

  const handleQuestionCheck = (e, index) => {
    setQuestion({ ...question, answer: index });
  };

  const handleQuestionSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      question: question.question,
      options: question.options,
      answer: question.options[question.answer],
    };

    if (question.tipText) {
      data.tipText = question.tipText;
    }

    if (question.tipActivationThreshold) {
      data.tipActivationThreshold = parseInt(question.tipActivationThreshold);
    }

    try {
      const response = await organizationAPI.createAssessmentQuestion(
        assessmentId,
        section._id,
        data
      );
      await getAssessment();
      setQuestion({
        question: '',
        options: ['', '', '', ''],
        answer: '',
        tipText: '',
        tipActivationThreshold: '',
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNewQuestion(false);
    }
    setLoading(false);
    setNewQuestion(false);
  };

  useEffect(() => {
    if (
      question.question &&
      question.options.every((option) => option.length > 0)
    ) {
      setDisableQuestionSaveButton(false);
    } else {
      setDisableQuestionSaveButton(true);
    }
  }, [question]);

  return (
    <>
      {newQuestion && (
        <>
          <div className={styles.question_form}>
            <div className={styles.mcq_header}>
              <p>Question 1</p>
              <div className={styles.question_button}>
                <button
                  className={styles.save}
                  onClick={handleQuestionSave}
                  disabled={disableQuestionSaveButton}
                >
                  <Check /> SAVE
                </button>
                <button
                  className={styles.rename}
                  onClick={() => {
                    setNewQuestion(false);
                    setQuestion({
                      question: '',
                      options: ['', '', '', ''],
                      answer: '',
                    });
                  }}
                >
                  <Close /> DISCARD
                </button>
              </div>
            </div>
            <CustomTextField
              label="QUESTION"
              placeholder={'ENTER QUESTION'}
              fullWidth
              value={question.question}
              onChange={(e) =>
                setQuestion({ ...question, question: e.target.value })
              }
            />

            <div className={styles.border}></div>

            <div className={styles.mcq_section}>
              {question.options.map((option, index) => (
                <div key={index} className={styles.mcq_question_field}>
                  <p>Option {index + 1}</p>
                  <CustomTextField
                    label={`OPTION ${index + 1}`}
                    placeholder={`ENTER OPTION ${index + 1}`}
                    fullWidth
                    value={question.options[index]}
                    onChange={(e) => {
                      const temp = [...question.options];
                      temp[index] = e.target.value;
                      setQuestion({ ...question, options: temp });
                    }}
                  />
                  <Checkbox
                    checked={question.answer === index}
                    onChange={(e) => handleQuestionCheck(e, index)}
                  />
                  <span>Correct</span>
                  <Remove
                    onClick={() => {
                      const temp = [...question.options];
                      temp.splice(index, 1);
                      setQuestion({ ...question, options: temp });
                    }}
                  />
                </div>
              ))}
            </div>
            <div
              style={{
                marginTop: '20px',
              }}
              className={styles.new_question}
              onClick={() => {
                setQuestion({
                  ...question,
                  options: [...question.options, ''],
                });
              }}
            >
              <Add />

              <p>Add New Option</p>
            </div>
            <div className={styles.tip_section}>
              <CustomTextField
                label="TIP"
                placeholder={
                  'Add a helpful tip that can help candidates with their self-improvement'
                }
                fullWidth
                value={question.tipText}
                onChange={(e) =>
                  setQuestion({ ...question, tipText: e.target.value })
                }
              />
              <CustomTextField
                label="SCORE THRESHOLD FOR TIP ACTIVATION"
                placeholder={'SCORE THRESHOLD FOR TIP ACTIVATION'}
                fullWidth
                value={question.tipActivationThreshold}
                onChange={(e) =>
                  setQuestion({
                    ...question,
                    tipActivationThreshold: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </>
      )}
      {!newQuestion && (
        <div
          className={styles.new_question}
          onClick={() => setNewQuestion(true)}
        >
          <Add />

          <p>Add New Question</p>
        </div>
      )}
    </>
  );
};

const RemoveFlagModal = ({
  open,
  setOpen,
  setLoading,
  getAssessment,
  setQuestionFlag,
  questionFlag,
  selectedFlagIndex,
  assessmentId,
  sectionId,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);

    const temp = [...questionFlag];
    temp.splice(selectedFlagIndex, 1);

    try {
      const response = await organizationAPI.updateAssessmentSection(
        assessmentId,
        sectionId,
        { malingeringFlags: temp }
      );
      await getAssessment();
      setQuestionFlag(temp);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Flag</h5>
          <p>
            Are you sure you want to delete this flag? This action cannot be
            undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const RemoveConditionModal = ({
  open,
  setOpen,
  setLoading,
  getAssessment,
  assessmentId,
  sectionId,
  otherConditions,
  setOtherConditions,
  selectedConditionIndex,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);

    const temp = [...otherConditions];
    temp.splice(selectedConditionIndex, 1);

    try {
      const response = await organizationAPI.updateAssessmentSection(
        assessmentId,
        sectionId,
        { otherConditions: temp }
      );
      await getAssessment();
      setOtherConditions(temp);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Conition</h5>
          <p>
            Are you sure you want to delete this condition? This action cannot
            be undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const EditQuestionSection = ({
  assessmentId,
  sectionData,
  setLoading,
  getAssessment,
  setEdit,
}) => {
  const [newSectionData, setNewSectionData] = useState({
    title: sectionData.title,
    template: sectionData.template,
    scoringType: sectionData.scoringType,
    scoringMultiplier: sectionData.scoringMultiplier,
  });

  const [questionFlag, setQuestionFlag] = useState(
    sectionData.malingeringFlags
  );

  const [otherConditions, setOtherConditions] = useState(
    sectionData.otherConditions
  );

  const [removeFlagModalOpen, setRemoveFlagModalOpen] = useState(false);
  const [selectedFlagIndex, setSelectedFlagIndex] = useState(null);

  const [removeConditionModalOpen, setRemoveConditionModalOpen] =
    useState(false);
  const [selectedConditionIndex, setSelectedConditionIndex] = useState(null);

  const updateSection = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      title: newSectionData.title,
      template: newSectionData.template,
      scoringMultiplier: newSectionData.scoringMultiplier,
    };

    if (newSectionData.scoringType) {
      data.scoringType = newSectionData.scoringType;
    }

    if (questionFlag.length > 0) {
      data.malingeringFlags = questionFlag;
    }

    if (otherConditions.length > 0) {
      data.otherConditions = otherConditions;
    }
    try {
      const response = await organizationAPI.updateAssessmentSection(
        assessmentId,
        sectionData._id,
        data
      );
      await getAssessment();
      setNewSectionData({
        title: '',
        template: 'Basic 4-option MCQ',
        scoringType: '',
        scoringMultiplier: 1,
      });

      setQuestionFlag([]);
      setOtherConditions([]);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setEdit(false);
    }
    setLoading(false);
    setEdit(false);
  };

  return (
    <>
      <RemoveFlagModal
        open={removeFlagModalOpen}
        setOpen={setRemoveFlagModalOpen}
        setLoading={setLoading}
        getAssessment={getAssessment}
        setQuestionFlag={setQuestionFlag}
        questionFlag={questionFlag}
        selectedFlagIndex={selectedFlagIndex}
        assessmentId={assessmentId}
        sectionId={sectionData._id}
      />

      <RemoveConditionModal
        open={removeConditionModalOpen}
        setOpen={setRemoveConditionModalOpen}
        setLoading={setLoading}
        getAssessment={getAssessment}
        setOtherConditions={setOtherConditions}
        otherConditions={otherConditions}
        selectedConditionIndex={selectedConditionIndex}
        assessmentId={assessmentId}
        sectionId={sectionData._id}
      />

      <div className={styles.question_section}>
        <CustomTextField
          label="SECTION TITLE"
          placeholder={'ENTER SECTION TITLE'}
          value={newSectionData.title}
          onChange={(e) => {
            setNewSectionData({ ...newSectionData, title: e.target.value });
          }}
          fullWidth
        />

        <div className={styles.question_format}>
          <FormControl
            sx={{
              width: '93%',
            }}
            className={styles.dropdown}
          >
            <SelectQuestionFormatTemplate
              label={'Select Question Format Template'}
              input={'Question Format'}
              value={newSectionData.template}
              onChange={(e) => {
                setNewSectionData({
                  ...newSectionData,
                  template: e.target.value,
                });
              }}
            />
          </FormControl>
          <button onClick={updateSection}>Confirm</button>
        </div>
        <FormControl className={styles.scoring_radio}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={newSectionData.scoringType}
          >
            <FormControlLabel
              value="normal"
              onChange={(e) => {
                setNewSectionData({
                  ...newSectionData,
                  scoringType: e.target.value,
                });
              }}
              control={<Radio size="small" />}
              label={
                <div className={styles.radio_label}>
                  <p>Normal Scoring</p>
                  <p>
                    Options A, B, C and D are assigned respective scores of 1,
                    2, 3 and 4
                  </p>
                </div>
              }
            />
            <FormControlLabel
              value="inverse"
              onChange={(e) => {
                setNewSectionData({
                  ...newSectionData,
                  scoringType: e.target.value,
                });
              }}
              control={<Radio size="small" />}
              label={
                <div className={styles.radio_label}>
                  <p>Inverse Scoring</p>
                  <p>
                    Options A, B, C and D are assigned respective scores of 4,
                    3, 2 and 1
                  </p>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
        <CustomTextField
          label="SCORE MULTIPLIER"
          placeholder={'ENTER SCORE MULTIPLIER'}
          value={newSectionData.scoringMultiplier}
          onChange={(e) => {
            setNewSectionData({
              ...newSectionData,
              scoringMultiplier: e.target.value,
            });
          }}
          fullWidth
        />

        <div className={styles.question_flag_section}>
          {questionFlag.map((flag, index) => (
            <div className={styles.question_flag} key={index}>
              <CustomTextField
                label="QUESTION FLAG"
                placeholder={'QUESTION FLAG'}
                multiline
                value={flag}
                onChange={(e) => {
                  const temp = [...questionFlag];
                  temp[index] = e.target.value;
                  setQuestionFlag(temp);
                }}
                fullWidth
              />
              <Remove
                onClick={() => {
                  setSelectedFlagIndex(index);
                  setRemoveFlagModalOpen(true);
                }}
              />
            </div>
          ))}
          <div
            className={styles.new_question}
            onClick={() => setQuestionFlag([...questionFlag, ''])}
          >
            <Add />

            <p>Add New Flag</p>
          </div>
        </div>

        <div className={styles.question_flag_section}>
          {otherConditions.map((conditions, index) => (
            <div className={styles.question_flag} key={index}>
              <CustomTextField
                multiline
                label="OTHER CONDITIONS"
                placeholder={'ENTER OTHER CONDITIONS'}
                value={conditions}
                onChange={(e) => {
                  const temp = [...otherConditions];
                  temp[index] = e.target.value;
                  setOtherConditions(temp);
                }}
                fullWidth
              />
              <Remove
                onClick={() => {
                  setSelectedConditionIndex(index);
                  setRemoveConditionModalOpen(true);
                }}
              />
            </div>
          ))}
          <div
            className={styles.new_question}
            onClick={() => setOtherConditions([...otherConditions, ''])}
          >
            <Add />

            <p>Add New Condition</p>
          </div>
        </div>
      </div>
    </>
  );
};

const QuestionSection = ({
  assessment,
  setLoading,
  getAssessment,
  location,
}) => {
  const [newSection, setNewSection] = useState(false);
  const [questionFlag, setQuestionFlag] = useState([]);
  const [otherConditions, setOtherConditions] = useState([]);
  const [openRemoveSectionModal, setOpenRemoveSectionModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState({});
  const assessmentId = location.pathname.split('/')[4];
  const [edit, setEdit] = useState(false);

  const editedSectionId = new URLSearchParams(location.search).get('sectionId');

  useEffect(() => {
    if (editedSectionId) {
      setEdit(parseInt(editedSectionId));
    }
  }, [editedSectionId]);

  const [newSectionData, setNewSectionData] = useState({
    title: '',
    template: 'Basic 4-option MCQ',
    scoringType: '',
    scoringMultiplier: 1,
  });

  const createSection = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      title: newSectionData.title,
      template: newSectionData.template,
      scoringMultiplier: newSectionData.scoringMultiplier,
    };

    if (newSectionData.scoringType) {
      data.scoringType = newSectionData.scoringType;
    }

    if (questionFlag.length > 0) {
      data.malingeringFlags = questionFlag;
    }

    if (otherConditions.length > 0) {
      data.otherConditions = otherConditions;
    }
    try {
      const response = await organizationAPI.createAssessmentSection(
        assessmentId,
        data
      );
      await getAssessment();
      setNewSectionData({
        title: '',
        template: 'Basic 4-option MCQ',
        scoringType: '',
        scoringMultiplier: 1,
      });

      setQuestionFlag([]);
      setOtherConditions([]);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNewSection(false);
    }
    setLoading(false);
    setNewSection(false);
  };
  return (
    <>
      <RemoveSectionModal
        open={openRemoveSectionModal}
        setOpen={setOpenRemoveSectionModal}
        setLoading={setLoading}
        assessmentId={assessmentId}
        sectionId={selectedSection._id}
        getAssessment={getAssessment}
      />
      {assessment?.sections.length > 0 &&
        assessment?.sections.map((section, index) => {
          return (
            <>
              {edit === index ? (
                <>
                  <EditQuestionSection
                    assessmentId={assessmentId}
                    sectionData={section}
                    setLoading={setLoading}
                    getAssessment={getAssessment}
                    setEdit={setEdit}
                  />
                </>
              ) : (
                <div className={styles.question_section} key={index}>
                  <div className={styles.question_list}>
                    <div className={styles.question_content}>
                      <h3>{section.title}</h3>
                      <div className={styles.question_button}>
                        {/* <p className={styles.save}>
                  <Check /> SAVE
                </p>
                <p className={styles.rename}>
                  <Close /> DISCARD
                </p> */}
                        <p
                          className={styles.delete}
                          onClick={() => {
                            setSelectedSection(section);
                            setOpenRemoveSectionModal(true);
                          }}
                        >
                          <Delete /> DELETE
                        </p>
                        <p
                          className={styles.rename}
                          onClick={() => {
                            setEdit(index);
                          }}
                        >
                          <Edit /> EDIT
                        </p>
                      </div>
                    </div>
                    <p
                      className={styles.question_type}
                    >{`${section.template} • ${section.scoringType}`}</p>
                    <div className={styles.border}></div>
                    {section.questions &&
                      section.questions.map((question, index) => {
                        return (
                          <MCQQuestionPreviewSection
                            assessmentId={assessmentId}
                            section={section}
                            item={question}
                            index={index}
                            key={index}
                            setLoading={setLoading}
                            getAssessment={getAssessment}
                          />
                        );
                      })}

                    <MCQQuestionSection
                      assessmentId={assessmentId}
                      section={section}
                      setLoading={setLoading}
                      getAssessment={getAssessment}
                    />
                  </div>
                </div>
              )}
            </>
          );
        })}

      {newSection && (
        <>
          <div className={styles.question_section}>
            <CustomTextField
              label="SECTION TITLE"
              placeholder={'ENTER SECTION TITLE'}
              value={newSectionData.title}
              onChange={(e) => {
                setNewSectionData({ ...newSectionData, title: e.target.value });
              }}
              fullWidth
            />

            <div className={styles.question_format}>
              <FormControl
                sx={{
                  width: '93%',
                }}
                className={styles.dropdown}
              >
                <SelectQuestionFormatTemplate
                  label={'Select Question Format Template'}
                  input={'Question Format'}
                  value={newSectionData.template}
                  onChange={(e) => {
                    setNewSectionData({
                      ...newSectionData,
                      template: e.target.value,
                    });
                  }}
                />
              </FormControl>
              <button onClick={createSection}>Confirm</button>
            </div>
            <FormControl className={styles.scoring_radio}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="normal"
                  onChange={(e) => {
                    setNewSectionData({
                      ...newSectionData,
                      scoringType: e.target.value,
                    });
                  }}
                  control={<Radio size="small" />}
                  label={
                    <div className={styles.radio_label}>
                      <p>Normal Scoring</p>
                      <p>
                        Options A, B, C and D are assigned respective scores of
                        1, 2, 3 and 4
                      </p>
                    </div>
                  }
                />
                <FormControlLabel
                  value="inverse"
                  onChange={(e) => {
                    setNewSectionData({
                      ...newSectionData,
                      scoringType: e.target.value,
                    });
                  }}
                  control={<Radio size="small" />}
                  label={
                    <div className={styles.radio_label}>
                      <p>Inverse Scoring</p>
                      <p>
                        Options A, B, C and D are assigned respective scores of
                        4, 3, 2 and 1
                      </p>
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
            <CustomTextField
              label="SCORE MULTIPLIER"
              placeholder={'ENTER SCORE MULTIPLIER'}
              value={newSectionData.scoringMultiplier}
              onChange={(e) => {
                setNewSectionData({
                  ...newSectionData,
                  scoringMultiplier: e.target.value,
                });
              }}
              fullWidth
            />

            <div className={styles.question_flag_section}>
              {questionFlag.map((flag, index) => (
                <div className={styles.question_flag} key={index}>
                  <CustomTextField
                    label="QUESTION FLAG"
                    placeholder={'QUESTION FLAG'}
                    multiline
                    value={flag}
                    onChange={(e) => {
                      const temp = [...questionFlag];
                      temp[index] = e.target.value;
                      setQuestionFlag(temp);
                    }}
                    fullWidth
                  />
                  <Remove
                    onClick={() => {
                      const temp = [...questionFlag];
                      temp.splice(index, 1);
                      setQuestionFlag(temp);
                    }}
                  />
                </div>
              ))}
              <div
                className={styles.new_question}
                onClick={() => setQuestionFlag([...questionFlag, ''])}
              >
                <Add />

                <p>Add New Flag</p>
              </div>
            </div>

            <div className={styles.question_flag_section}>
              {otherConditions.map((conditions, index) => (
                <div className={styles.question_flag} key={index}>
                  <CustomTextField
                    multiline
                    label="OTHER CONDITIONS"
                    placeholder={'ENTER OTHER CONDITIONS'}
                    value={conditions}
                    onChange={(e) => {
                      const temp = [...otherConditions];
                      temp[index] = e.target.value;
                      setOtherConditions(temp);
                    }}
                    fullWidth
                  />
                  <Remove
                    onClick={() => {
                      const temp = [...otherConditions];
                      temp.splice(index, 1);
                      setOtherConditions(temp);
                    }}
                  />
                </div>
              ))}
              <div
                className={styles.new_question}
                onClick={() => setOtherConditions([...otherConditions, ''])}
              >
                <Add />

                <p>Add New Condition</p>
              </div>
            </div>
          </div>
        </>
      )}

      {!newSection && (
        <div
          className={styles.question_add}
          onClick={() => setNewSection(true)}
        >
          <p>Add New Section</p>
          <Add />
        </div>
      )}
    </>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [testList, setTestList] = useState([]);
  const [assessment, setAssessment] = useState(null);
  const [conditionSummary, setConditionSummary] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [comment, setComment] = useState([]);
  const [commentModalOpen, setCommentModalOpen] = useState(false);

  const [status, setStatus] = useState(null);
  const [activeSection, setActiveSection] = useState();

  const [user, setUser] = useState(null);

  const location = useLocation();
  const tab = new URLSearchParams(location.search).get('tab');

  const assessmentId = location.pathname.split('/')[4];

  useEffect(() => {
    if (!tab) {
      setActiveSection('question');
    } else if (tab === 'question') {
      setActiveSection('question');
    } else if (tab === 'review') {
      setActiveSection('review');
    } else if (tab === 'publishing') {
      setActiveSection('publishing');
    }
  }, [tab]);

  const getConditionsSummary = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentConditionSummary(
        assessmentId
      );
      setConditionSummary(response.data.summary);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getAssessment = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentId(assessmentId);
      // update response data status first letter to uppercase
      response.data.status =
        response.data.status.charAt(0).toUpperCase() +
        response.data.status.slice(1);
      setStatus(response.data.status);
      setAssessment(response.data);
      setComment(response.data.comments);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleStatusChange = async (e) => {
    setLoading(true);
    const tempStatus = status.toLowerCase();
    const response = await organizationAPI.updateAssessment(assessmentId, {
      status: tempStatus,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (status) {
      handleStatusChange();
    }
  }, [status]);

  useEffect(() => {
    getMe();
    getAssessment();
  }, []);

  return (
    <>
      <MainContent active="psych" user={user}>
        {loading && <LoadingOverlay />}
        <CommentModal
          open={commentModalOpen}
          user={user}
          setOpen={setCommentModalOpen}
          assessmentId={assessmentId}
          getAssessment={getAssessment}
          comment={comment}
          setComment={setComment}
          setLoading={setLoading}
        />
        <div className={styles.content}>
          <div className={styles.learner_header}>
            <h1>{assessment?.assessmentName}</h1>

            <div className={styles.learner_header_buttons}>
              <button
                className={styles.discard_button}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Close />
                Discard
              </button>
              <button
                className={styles.comment_button}
                onClick={() => setCommentModalOpen(true)}
              >
                <Comment />
                Comments
              </button>

              {/* <button
                className={styles.condition_button}
                onClick={() => setCreateModalOpen(true)}
              >
                Set Condition
                <ChevronRight />
              </button> */}
            </div>
          </div>
          <div className={styles.border}></div>

          {assessment && (
            <div className={styles.assement_details}>
              <CustomTextField
                label="Assessment Name"
                value={assessment.assessmentName}
                disabled
              />

              <CustomTextField
                label="Created By"
                value={`${assessment.createdBy} `}
                disabled
              />
              {/* <FormControl
                sx={{
                  width: '24%',
                }}
                className={styles.dropdown}
              >
                <SelectTimeAllowed
                  label={'Select Time Allowed'}
                  input={'Time Allowed'}
                  value={timeAllowed}
                  setValue={setTimeAllowed}
                />
              </FormControl> */}
              <FormControl
                sx={{
                  width: '33%',
                }}
                className={styles.dropdown}
              >
                <SelectAssementStatus
                  label="Select Status"
                  input={'Status'}
                  value={status}
                  setValue={setStatus}
                />
              </FormControl>
            </div>
          )}

          <div className={styles.assement_section}>
            <ul className={styles.assement_list}>
              <li
                className={activeSection === 'question' && styles.active}
                onClick={() => {
                  getAssessment();
                  setSearchParams({ tab: 'question' });
                }}
              >
                1. Questions
              </li>
              <li
                className={activeSection === 'review' && styles.active}
                onClick={() => {
                  getConditionsSummary();
                  setSearchParams({ tab: 'review' });
                }}
              >
                2. Review
              </li>
              <li
                className={activeSection === 'publishing' && styles.active}
                onClick={() => {
                  setSearchParams({ tab: 'publishing' });
                }}
              >
                3. Publishing
              </li>
            </ul>

            <div className={styles.assement_section_content}>
              {activeSection === 'question' && (
                <QuestionSection
                  assessment={assessment}
                  setLoading={setLoading}
                  getAssessment={getAssessment}
                  location={location}
                />
              )}
              {activeSection === 'review' && (
                <ReviewSection
                  assessmentId={assessmentId}
                  conditionSummary={conditionSummary}
                  setLoading={setLoading}
                  assessment={assessment}
                  getAssessment={getAssessment}
                  getConditionsSummary={getConditionsSummary}
                  setSearchParams={setSearchParams}
                />
              )}
              {activeSection === 'publishing' && assessment && (
                <PublishSection
                  setLoading={setLoading}
                  assessmentId={assessmentId}
                  assessment={assessment}
                  getAssessment={getAssessment}
                />
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </MainContent>
    </>
  );
};

export default Dashboard;
