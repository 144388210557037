import axios from 'axios';
import { getBaseUrl } from './config';
const baseUrl = getBaseUrl();

const baseApi = axios.create({
  baseURL: `${baseUrl}`,
  validateStatus: function (status) {
    function changeURLToLogin(url) {
      const urlObj = new URL(url);
      urlObj.pathname = '/'; // Change the pathname
      const modifiedURL = urlObj.toString(); // Add the modified URL to the array

      return modifiedURL;
    }

    if (status === 401 && window.location.pathname.split('/')[2] !== 'login') {
      window.location.href = changeURLToLogin(window.location);
    }

    return status >= 200 && status < 300; // default
  },
});

// baseApi.interceptors.request.use((request) => {
//   console.log('Starting Request', JSON.stringify(request, null, 2));
//   return request;
// });

// baseApi.interceptors.response.use((response) => {
//   console.log('Response:', JSON.stringify(response, null, 2));
//   return response;
// });

// Save the organizationCode to localStorage
const saveOrganizationCodeToLocalStorage = async (organizationCode) => {
  await localStorage.setItem('organizationCode', organizationCode);
};

// Retrieve the organizationCode from localStorage
const getOrganizationCodeFromLocalStorage = () => {
  return localStorage.getItem('organizationCode');
};

// Save the token to localStorage
const saveTokenToLocalStorage = (token) => {
  localStorage.setItem('jwtToken', token);
};

// Retrieve the token from localStorage
const getTokenFromLocalStorage = () => {
  return localStorage.getItem('jwtToken');
};

// Save the token to localStorage
const saveCoachIDToLocalStorage = (id) => {
  localStorage.setItem('defaultCoachId', id);
};

// Retrieve the token from localStorage
const getCoachIDToLocalStorage = () => {
  return localStorage.getItem('defaultCoachId');
};

// Remove the token from localStorage
const removeTokenFromLocalStorage = () => {
  localStorage.removeItem('jwtToken');
};

const getUserProfile = async () => {
  try {
    const response = await baseApi.get('/api/v1/auth/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    if (response.data.data.type === 'pg') {
      saveCoachIDToLocalStorage('648e67ba10ee9307c26f87ab');
    } else if (response.data.data.type === 'elevate') {
      saveCoachIDToLocalStorage('648de163f0448cac872de27d');
    } else {
      console.log('invalid type');
    }

    return response;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const getCoachProfile = async () => {
  try {
    const response = await baseApi.get('/api/v1/auth/coach/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const userLogin = async (data) => {
  const response = await baseApi.post(`/api/v1/auth/login`, data);
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const userSignup = async (data) => {
  const response = await baseApi.post(`/api/v1/auth/register`, data);
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const submitQuestion = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/askCoach`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const submitFeedback = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/giveFeedback`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const coachLogin = async (data) => {
  const response = await baseApi.post(`/api/v1/auth/coach/login`, data);
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const getUserQuestions = async () => {
  try {
    const response = await baseApi.get('/api/v1/coach/getUserQuestions', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });

    // Handle the response data
    const userData = response.data;
    return userData;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const getCoachQuestions = async () => {
  try {
    const response = await baseApi.get('/api/v1/coach/getAllCoachQuestions', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });

    // Handle the response data
    const userData = response.data;
    return userData;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const organizationUserLogin = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user/login`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const organizationUserRegistration = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/register`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const organizationUserForget = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/forget`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response;
};

const organizationUserReset = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user/reset`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

const googleSignup = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const inviteUser = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/invitation`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getUsersList = async (organizationId, role) => {
  if (role) {
    const response = await baseApi.get(
      `/api/v1/organization/users/${organizationId}?role=${role}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );

    return response;
  }

  const response = await baseApi.get(
    `/api/v1/organization/users/${organizationId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getUser = async () => {
  const response = await baseApi.get(`/api/v1/organization/me`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const updateAssignees = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/users/assignee`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteUser = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/delete`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const resendInvitation = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/invitation/resend`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const revokeInvitation = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/invitation/revoke`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const askQuestion = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/ask`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getQuestion = async (organization_id, userId) => {
  const response = await baseApi.get(
    `/api/v1/organization/users/userQuestions/${organization_id}/${userId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateStar = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/updateStarredFlag`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const updateQuestionClose = async (data) => {
  const response = await baseApi.post(
    `/api/v1/coach/updateClosedQuestionFlag`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getMe = async () => {
  const response = await baseApi.get(`/api/v1/organization/me`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getCoachQuestion = async (organization_id, userId) => {
  const response = await baseApi.get(
    `/api/v1/organization/users/coachQuestions/${organization_id}/${userId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const coachFeedback = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/giveFeedback`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

/////////////////////// CANVAS API

const createCanvas = async (data) => {
  const response = await baseApi.post(`/api/v1/canvas`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getAllCanvas = async (userId) => {
  const response = await baseApi.get(`/api/v1/canvas/user/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getIndividualCanvas = async (canvasId) => {
  const response = await baseApi.get(`/api/v1/canvas/${canvasId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const addCanvasWorkflowItem = async (data, canvasId) => {
  const response = await baseApi.post(
    `/api/v1/canvas/addWorkflowItem/${canvasId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateCanvasWorkflowItem = async (data, canvasId, workflowId) => {
  const response = await baseApi.put(
    `/api/v1/canvas/updateWorkflowItem/${canvasId}/${workflowId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteCanvasWorkflowItem = async (canvasId, workflowId) => {
  const response = await baseApi.delete(
    `/api/v1/canvas/deleteWorkflowItem/${canvasId}/${workflowId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const submitDraft = async (data) => {
  const response = await baseApi.post(
    `/api/v1/grading/askLeanCanvasAIForGrading`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addCommentToCanvas = async (data, canvasId) => {
  const response = await baseApi.post(
    `/api/v1/canvas/comment/${canvasId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAllCanvasForCoach = async () => {
  const response = await baseApi.get(`/api/v1/canvas/coach/all`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getIndividualCanvasForCoach = async (canvasId) => {
  const response = await baseApi.get(
    `/api/v1/canvas/coach/all?canvasId=${canvasId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getGradingAIForIndividualCanvas = async (canvasId) => {
  const response = await baseApi.get(`/api/v1/grading/canvas/${canvasId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const updateCanvasTile = async (data) => {
  const response = await baseApi.post(
    `/api/v1/grading/updateCanvasTile`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateCanvasGradingStatus = async (data) => {
  const response = await baseApi.post(
    `/api/v1/grading/updateCanvasGradingStatus`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateCanvasStatus = async (canvasId, status) => {
  const response = await baseApi.put(
    `/api/v1/canvas/updateStatus/${canvasId}/${status}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAllCanvasStudent = async (canvasId, status) => {
  const response = await baseApi.get(`/api/v1/canvas/students/all`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const addCollaboratorToCanvas = async (data, canvasId) => {
  const response = await baseApi.post(
    `/api/v1/canvas/addMembers/${canvasId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteCollaboratorToCanvas = async (canvasId, userId) => {
  const response = await baseApi.delete(
    `/api/v1/canvas/deleteMember/${canvasId}/${userId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const bulkInvitee = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/invitation/bulk`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAllCourses = async () => {
  const response = await baseApi.get(`/api/v1/organization/course`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const createCourse = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/course`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const deleteCourse = async (courseId) => {
  const response = await baseApi.delete(
    `/api/v1/organization/course/${courseId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getStatistics = async (data) => {
  const response = await baseApi.post(
    `/api/v1/statistics/filter/summary`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const createAdmissionTemplate = async (data) => {
  const response = await baseApi.post(`/api/v1/admissionTemplate`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getAllAdmissionTemplates = async () => {
  const response = await baseApi.get(`/api/v1/admissionTemplate/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getAdmissionTemplateById = async (admissionId) => {
  const response = await baseApi.get(
    `/api/v1/admissionTemplate/${admissionId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAdmissionTemplateByUser = async () => {
  const response = await baseApi.get(
    `/api/v1/admissionTemplate/user/templates`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addAdmissionTemplateAcademicInterest = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/academicInterest`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addAdmissionTemplateExtracurricularActivities = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/extracurricularActivity`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addAdmissionTemplatePersonalExperiences = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/personalExperiences`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addAdmissionTemplateFutureGoals = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/futureGoals`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addAdmissionTemplateSpecificInterest = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/specificInterest`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addAdmissionTemplatEssayTopic = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/essayTopic`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const submitEssay = async (data) => {
  const response = await baseApi.post(`/api/v1/essay/evaluateEssay`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getEssayById = async (essayId) => {
  const response = await baseApi.get(
    `/api/v1/essay/getEssayByTemplate/${essayId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateEssayImprovementSuggestions = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateEssayImprovementSuggestions/${essayId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateCollegeDetails = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateCollegeDetails/${essayId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateEssayRemarks = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateEssayRemarks/${essayId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateGrade = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateGrade/${essayId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getSummaryByCanvasId = async (canvasId) => {
  const response = await baseApi.get(`/api/v1/summarizeAI/canvas/${canvasId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const createSummary = async (data) => {
  const response = await baseApi.post(`/api/v1/summarizeAI`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getTeacherAIDocument = async () => {
  const response = await baseApi.get(`/api/v1/teacherAi`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const deleteTeacherAIDocumentFile = async (documentId, fileId) => {
  const response = await baseApi.delete(
    `/api/v1/teacherAi/deleteFile/${documentId}/${fileId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateTeacherAIDocumentFileStatus = async (data) => {
  const response = await baseApi.post(
    `/api/v1/teacherAi/updateFileStatus`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const uploadTeacherAI = async (formData) => {
  const response = await baseApi.post(
    `/api/v1/teacherAi/uploadKnowledge`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getTeacherAIChat = async () => {
  const response = await baseApi.get(`/api/v1/teacherAi/getChat`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const createTeacherAIChat = async (data) => {
  const response = await baseApi.post(`/api/v1/teacherAi/createChat`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const renameTeacherAIChat = async (data) => {
  const response = await baseApi.put(`/api/v1/teacherAi/renameChat`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const deleteTeacherAIChat = async (chatId) => {
  const response = await baseApi.delete(
    `/api/v1/teacherAi/deleteChat/${chatId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const documentAnalyzerUploadDocument = async (data) => {
  const response = await baseApi.post(
    `/api/v1/documentAnalyzer/uploadDocument`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getDocumentAnalyzeByUser = async () => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/getDocumentsByUser`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getDocumentAnalyzeByOrganization = async () => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/getDocumentsByOrganization`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getDocumentAnalyzeById = async (documentId) => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/document/${documentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteDocumentAnalayzeById = async (documentId) => {
  const response = await baseApi.delete(
    `/api/v1/documentAnalyzer/document/${documentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const addCommentToDocumentAnalyze = async (data, documentId) => {
  const response = await baseApi.post(
    `/api/v1/documentAnalyzer/comment/${documentId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateDocumentAnalyzeStatusById = async (documentId, data) => {
  const response = await baseApi.put(
    `/api/v1/documentAnalyzer/status/${documentId}/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const bulkActionDocumentAnalyze = async (data) => {
  const response = await baseApi.put(
    `/api/v1/documentAnalyzer/bulkUpdateStatus`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getOrganizationMetaByOrganizationMetaName = async (
  organizationMetaName
) => {
  const response = await baseApi.get(
    `/api/v1/organizationMeta/${organizationMetaName}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response;
};

const getOrganizationMetaByRole = async (organizationName) => {
  const response = await baseApi.get(
    `/api/v1/organizationMeta/organizationMetaDetails/${organizationName}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAcceptedUser = async (organization_id) => {
  const response = await baseApi.get(
    `/api/v1/organization/users/accepted/${organization_id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateOwnPassword = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/update-password`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateUserPassword = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/update-password/admin`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getResumeRuleId = async () => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/getResumeRuleByOrganization`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessment = async () => {
  const response = await baseApi.get(`/api/v1/assessment`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getAssessmentId = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/getAssessment/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const createAssessment = async (data) => {
  const response = await baseApi.post(`/api/v1/assessment`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const updateAssessment = async (assessmentId, data) => {
  const response = await baseApi.put(
    `/api/v1/assessment/${assessmentId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteAssessment = async (assessmentId) => {
  const response = await baseApi.delete(`/api/v1/assessment/${assessmentId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const createAssessmentSection = async (assessmentId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/${assessmentId}/section`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const createAssessmentQuestion = async (assessmentId, sectionId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/${assessmentId}/question/${sectionId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateAssessmentSection = async (assessmentId, sectionId, data) => {
  const response = await baseApi.put(
    `/api/v1/assessment/${assessmentId}/section/${sectionId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateAssessmentQuestion = async (
  assessmentId,
  sectionId,
  questionId,
  data
) => {
  const response = await baseApi.put(
    `/api/v1/assessment/${assessmentId}/question/${sectionId}/${questionId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteAssessmentSection = async (assessmentId, sectionId) => {
  const response = await baseApi.delete(
    `/api/v1/assessment/${assessmentId}/section/${sectionId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteAssessmentQuestion = async (
  assessmentId,
  sectionId,
  questionId
) => {
  const response = await baseApi.delete(
    `/api/v1/assessment/${assessmentId}/question/${sectionId}/${questionId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessmentConditionSummary = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/conditions/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessmentGradeSummary = async (userId, assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/grades/summary/${userId}/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessmentDetailsStudent = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/assessmentResults/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessmentStudentTips = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/evaluation/tips/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getOrganizationCourseRegionSchools = async () => {
  const response = await baseApi.get(`/api/v1/organization/search`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const publishRegionCourseSchoolAssessment = async (assessmentId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/publish/${assessmentId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteAssessmentPublish = async (assessmentId, publishId) => {
  const response = await baseApi.delete(
    `/api/v1/assessment/publish/${assessmentId}/${publishId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAIChat = async () => {
  const response = await baseApi.get(`/api/v1/coach/getCoachTeacherQuestions`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const createAIChat = async (data) => {
  const response = await fetch(`${baseUrl}/api/v1/coach/askForTeacher`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.body;
};

const createAIChatWithFile = async (data) => {
  const response = await baseApi.post(
    `/api/v1/coach/uploadDocumentFromTeacher`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteAIChat = async (chatId) => {
  const response = await baseApi.delete(
    `/api/v1/coach/deleteCoachTeacherAI/${chatId}`,

    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getRegionalBreakdown = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/regionalBreakdownDashboard/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getStudentAssessmentDashboard = async () => {
  const response = await baseApi.get(`/api/v1/assessment/userDashboard`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const getStudentAssessmentEvaluation = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/assessmentResults/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const submitAssessmentEvaluationAnswer = async (evaluationId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/evaluation/${evaluationId}/question`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessmentUserList = async () => {
  const response = await baseApi.get(`/api/v1/assessment/userList`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const addUserToAssessmentEvaluation = async (data) => {
  const response = await baseApi.post(`/api/v1/assessment/evaluation`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
  });

  return response;
};

const addCommentToAssessment = async (data, assessmentId) => {
  const response = await baseApi.post(
    `/api/v1/assessment/comment/${assessmentId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessmentCourseGradeSummary = async (assessmentId, courseId) => {
  const response = await baseApi.get(
    `api/v1/assessment/regionalBreakdownDashboard/${assessmentId}?summary=${courseId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const updateAssessmentEvaluation = async (assessmentEvaluationId, data) => {
  const response = await baseApi.put(
    `/api/v1/assessment/evaluation/status/${assessmentEvaluationId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getAssessmentEvalutionTips = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/evaluation/tips/${assessmentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

export default {
  saveOrganizationCodeToLocalStorage,
  getOrganizationCodeFromLocalStorage,
  userLogin,
  getTokenFromLocalStorage,
  getCoachIDToLocalStorage,
  getUserProfile,
  userSignup,
  coachLogin,
  getCoachProfile,
  submitQuestion,
  getUserQuestions,
  getCoachQuestions,
  submitFeedback,
  organizationUserLogin,
  organizationUserRegistration,
  organizationUserForget,
  organizationUserReset,
  googleSignup,
  inviteUser,
  getUsersList,
  updateAssignees,
  deleteUser,
  resendInvitation,
  revokeInvitation,
  getUser,
  askQuestion,
  getQuestion,
  updateStar,
  updateQuestionClose,
  getMe,
  getCoachQuestion,
  coachFeedback,
  bulkInvitee,

  /////////////////////// COURSE
  getAllCourses,
  createCourse,
  deleteCourse,

  /////////////////////// CANVAS API
  createCanvas,
  getAllCanvas,
  getIndividualCanvas,
  addCanvasWorkflowItem,
  updateCanvasWorkflowItem,
  deleteCanvasWorkflowItem,
  submitDraft,
  addCommentToCanvas,

  getAllCanvasForCoach,
  getIndividualCanvasForCoach,
  getGradingAIForIndividualCanvas,
  updateCanvasTile,
  updateCanvasGradingStatus,
  updateCanvasStatus,
  getAllCanvasStudent,
  addCollaboratorToCanvas,
  deleteCollaboratorToCanvas,

  ////// INSIGHT
  getStatistics,

  ////// ADMISSION
  createAdmissionTemplate,
  getAdmissionTemplateById,
  getAllAdmissionTemplates,
  getAdmissionTemplateByUser,
  addAdmissionTemplateAcademicInterest,
  addAdmissionTemplateExtracurricularActivities,
  addAdmissionTemplatePersonalExperiences,
  addAdmissionTemplateFutureGoals,
  addAdmissionTemplateSpecificInterest,
  addAdmissionTemplatEssayTopic,
  submitEssay,
  getEssayById,
  updateCollegeDetails,
  updateEssayImprovementSuggestions,
  updateEssayRemarks,
  updateGrade,

  ////// SUMMARY
  getSummaryByCanvasId,
  createSummary,

  ////// TEACHER AI
  getTeacherAIDocument,
  uploadTeacherAI,
  getTeacherAIChat,
  createTeacherAIChat,
  deleteTeacherAIDocumentFile,
  updateTeacherAIDocumentFileStatus,
  renameTeacherAIChat,
  deleteTeacherAIChat,

  ////// DOCUMENT ANALYZER
  documentAnalyzerUploadDocument,
  getDocumentAnalyzeByUser,
  getDocumentAnalyzeByOrganization,
  getDocumentAnalyzeById,
  deleteDocumentAnalayzeById,
  addCommentToDocumentAnalyze,
  updateDocumentAnalyzeStatusById,
  bulkActionDocumentAnalyze,
  getResumeRuleId,

  ////// ORGANIZATION META
  getOrganizationMetaByOrganizationMetaName,
  getOrganizationMetaByRole,

  ///// ACCEPTED USER
  getAcceptedUser,
  updateOwnPassword,
  updateUserPassword,

  ////// ASSESSMENT
  getAssessment,
  getAssessmentId,
  createAssessment,
  updateAssessment,
  deleteAssessment,
  createAssessmentSection,
  createAssessmentQuestion,
  updateAssessmentSection,
  updateAssessmentQuestion,
  deleteAssessmentSection,
  deleteAssessmentQuestion,
  getAssessmentConditionSummary,
  getAssessmentGradeSummary,
  getOrganizationCourseRegionSchools,
  publishRegionCourseSchoolAssessment,
  deleteAssessmentPublish,
  getRegionalBreakdown,
  getAssessmentDetailsStudent,
  getAssessmentStudentTips,
  ////// AI CHAT
  getAIChat,
  createAIChat,
  deleteAIChat,
  createAIChatWithFile,
  getStudentAssessmentDashboard,
  getStudentAssessmentEvaluation,
  submitAssessmentEvaluationAnswer,
  getAssessmentUserList,
  addUserToAssessmentEvaluation,
  addCommentToAssessment,
  getAssessmentCourseGradeSummary,
  updateAssessmentEvaluation,
  getAssessmentEvalutionTips,
};
