import React from 'react';
import './styles/App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/DateRangePicker/styles/index.css';

import SplashScreen from './components/SplashScreen';
import Login from './components/Login';
import NFTELogin from 'pages/Login/NFTELogin';
import NFTEForget from 'pages/Forget/NFTEForget';
import NFTEReset from 'pages/Reset/NFTEReset';
import Dashboard from 'pages/Dashboard/Dashboard';
import LearnerDashboard from 'pages/StudentView/Learner/Dashboard';

import LoginNew from './TeacherView/login';
import CoachLogin from './components/CoachLogin';
import Signup from './components/Signup';
import NewQuestion from './components/NewQuestion';
import ThankYouQuestion from './components/ThankYouQuestion';
import UserDashboard from './components/UserDashboard';
import CoachDashboard from './components/CoachDashboard';
import StudentConversationalTranscript from 'pages/StudentView/ConversationalAI/Transcript';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import NewQuery from './pages/StudentView/NewQuery/newQuerry';
import PostQuestion from './pages/StudentView/StudentQueryArchive/postQus';
import CoachQueryArchive from './pages/CoachView/CoachQueryArchive/CoachQueryArchive';
import CoachPostQus from './CoachQuery/coachpostQus';
import ProfileDetails from './pages/ProfileDetails/profile';
import Announcements from './Announcements/announcement';

import Businesspage from 'pages/StudentView/Learner/Businesspage';
import CoachLearnerDashboard from 'pages/CoachView/Learner/Canvas/Dashboard';
import CoachLearnerCanvas from 'pages/CoachView/Learner/Canvas/Canvas';
import RequireAuth from 'components/RequireAuth';
import InsightView from 'pages/AdminView/Insight/Insight';
import InsightUserDetails from 'pages/AdminView/Insight/InsightUserDetails';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from 'pages/PrivacyPolicy/TermsCondition';

import CoachAdmissionDashboard from 'pages/CoachView/Admission/Dashboard';
import CoachAddmissionSubmitted from 'pages/CoachView/Admission/SubmittedAssessment';
import CoachAdmissionSubmission from 'pages/CoachView/Admission/AdmissionPage';
import CoachDocumentAnalysisDashboard from 'pages/CoachView/DocumentAnalysis/Dashboard';
import CoachDocumentAnalysisView from 'pages/CoachView/DocumentAnalysis/View';
import StudentAdmissionDashboard from 'pages/StudentView/Admission/Dashboard';
import StudentAdmissionSubmission from 'pages/StudentView/Admission/AdmissionPage';
import StudentAdmissionAssessment from 'pages/StudentView/Admission/Assessment';
import StudentAddmissionSubmitted from 'pages/StudentView/Admission/SubmittedAssessment';
import StudentDocumentAnalysisDashboard from 'pages/StudentView/DocumentAnalysis/Dashboard';
import StudentNewDocumentAnalysisView from 'pages/StudentView/DocumentAnalysis/NewAnalysis';
import TeacherAIDashboard from 'pages/AdminView/TeacherAI/Dashboard';
import TutorAI from 'pages/AdminView/TeacherAI/TutorAI';
import StudentConversationalDashboard from 'pages/StudentView/ConversationalAI/Dashboard';
import StudentResumeDashboard from 'pages/StudentView/ResumeAI/Dashboard';
import StudentResumeAnalysis from 'pages/StudentView/ResumeAI/ResumeAnalysis';
import CoachResumeAnalysis from 'pages/CoachView/ResumeAI/ResumeAnalysis';
import CoachResumeDashboard from 'pages/CoachView/ResumeAI/Dashboard';
import CoachPsychDashboard from 'pages/CoachView/Psych/Dashboard';
import CoachPsychDetails from 'pages/CoachView/Psych/Details';
import CoachPsychCandidate from 'pages/CoachView/Psych/Candidate';
import CoachPsychAssessmentSummary from 'pages/CoachView/Psych/AssessmentSummary';
import CoachPsychClassSummary from 'pages/CoachView/Psych/ClassSummary';
import CoachAssistantAI from 'pages/CoachView/AssistantAI/AssistantAI';

import StudentPsychDashboard from 'pages/StudentView/Psych/Dashboard';
import StudentPsychAssessment from 'pages/StudentView/Psych/Assessment';
import StudentPsychAssessmentSummary from 'pages/StudentView/Psych/AssessmentSummary';
import StudentPsychAssessmentThankYou from 'pages/StudentView/Psych/ThankYou';
import TeacherAIUpload from 'pages/AdminView/TeacherAI/Upload';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate replace to="/nfte/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/loginnew" element={<LoginNew />} />
        <Route path="/coachLogin" element={<CoachLogin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route path="/newQuestion" element={<NewQuestion />} />
        <Route path="/thankYouQuestion" element={<ThankYouQuestion />} />
        <Route path="/userDashboard" element={<UserDashboard />} />
        <Route path="/coachDashboard" element={<CoachDashboard />} />
        <Route path="announcement" element={<Announcements />} />

        <Route path="/:organization/login" element={<NFTELogin />} />
        <Route path="/:organization/forget" element={<NFTEForget />} />
        <Route path="/:organization/resetpassword" element={<NFTEReset />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin/insights" element={<InsightView />} />
        <Route
          path="/admin/insights/user-details"
          element={<InsightUserDetails />}
        />

        <Route path="/profile" element={<ProfileDetails />} />

        <Route element={<RequireAuth role="student" />}>
          <Route path="/student/new-query" element={<NewQuery />} />
          <Route path="/student/query-archive" element={<PostQuestion />} />
          <Route
            path="/student/learner/canvas"
            element={<LearnerDashboard />}
          />
          <Route
            path="/student/learner/canvas/:canvasId/"
            element={<Businesspage />}
          />
        </Route>

        <Route path="/student/learner/business" element={<Businesspage />} />

        <Route path="/coach/query-archive" element={<CoachQueryArchive />} />
        <Route path="/coachpostQus" element={<CoachPostQus />} />
        <Route
          path="/coach/learner/canvas"
          element={<CoachLearnerDashboard />}
        />
        <Route
          path="/coach/learner/canvas/:canvasId/"
          element={<CoachLearnerCanvas />}
        />

        <Route
          path="/coach/admission/dashboard"
          element={<CoachAdmissionDashboard />}
        />

        <Route
          path="/coach/admission/essay/:admissionId"
          element={<CoachAddmissionSubmitted />}
        />

        <Route
          path="/coach/admission/submission/:admissionId"
          element={<CoachAdmissionSubmission />}
        />

        <Route
          path="/coach/documentanalysis/dashboard"
          element={<CoachDocumentAnalysisDashboard />}
        />

        <Route
          path="/coach/documentanalysis/view/:documentId"
          element={<CoachDocumentAnalysisView />}
        />

        <Route
          path="/student/admission/dashboard"
          element={<StudentAdmissionDashboard />}
        />
        <Route
          path="/student/admission/submission/:admissionId"
          element={<StudentAdmissionSubmission />}
        />

        <Route
          path="/student/admission/essay/:admissionId"
          element={<StudentAddmissionSubmitted />}
        />

        <Route
          path="/student/admission/essay/submit/:admissionId"
          element={<StudentAdmissionAssessment />}
        />

        <Route
          path="/student/documentanalysis/dashboard"
          element={<StudentDocumentAnalysisDashboard />}
        />

        <Route
          path="/student/documentanalysis/view/:documentId"
          element={<StudentNewDocumentAnalysisView />}
        />

        <Route
          path="/student/resumeanalysis/dashboard"
          element={<StudentResumeDashboard />}
        />

        <Route
          path="/student/resumeanalysis/view/:resumeId"
          element={<StudentResumeAnalysis />}
        />

        <Route
          path="/coach/resumeanalysis/dashboard"
          element={<CoachResumeDashboard />}
        />

        <Route
          path="/coach/resumeanalysis/view/:resumeId"
          element={<CoachResumeAnalysis />}
        />

        <Route
          path="/admin/psych/dashboard"
          element={<CoachPsychDashboard />}
        />

        <Route
          path="/admin/psych/details/:assessmentId"
          element={<CoachPsychDetails />}
        />

        <Route
          path="/admin/psych/candidates/:assessmentId"
          element={<CoachPsychCandidate />}
        />

        <Route
          path="/coach/psych/dashboard"
          element={<CoachPsychDashboard />}
        />

        <Route
          path="/coach/psych/details/:assessmentId"
          element={<CoachPsychDetails />}
        />

        <Route
          path="/coach/psych/candidates/:assessmentId"
          element={<CoachPsychCandidate />}
        />

        <Route path="/coach/assistantai" element={<CoachAssistantAI />} />
        <Route
          path="/coach/psych/assessmentsummary/:studentId/:assessmentId"
          element={<CoachPsychAssessmentSummary />}
        />

        <Route
          path="/coach/psych/classsummary/:studentId/:assessmentId"
          element={<CoachPsychClassSummary />}
        />

        <Route
          path="/student/psych/dashboard"
          element={<StudentPsychDashboard />}
        />

        <Route
          path="/student/psych/assessment/:assessmentId"
          element={<StudentPsychAssessment />}
        />

        <Route
          path="/student/psych/assessmentsummary/:studentId/:assessmentId"
          element={<StudentPsychAssessmentSummary />}
        />

        <Route
          path="/student/psych/assessment/:assessmentId/success"
          element={<StudentPsychAssessmentThankYou />}
        />

        {/* <Route
          path="/student/documentanalysis/view"
          element={<StudentDocumentAnalysisView />}
        /> */}
        <Route
          path="/admin/teacherai/dashboard"
          element={<TeacherAIDashboard />}
        />
        <Route path="/coach/teacherai/tutor" element={<TutorAI />} />
        <Route path="/admin/teacherai/upload" element={<TeacherAIUpload />} />
        <Route
          path="/student/coversationalai/dashboard"
          element={<StudentConversationalDashboard />}
        />

        <Route
          path="/student/coversationalai/transcript"
          element={<StudentConversationalTranscript />}
        />
      </Routes>
    </Router>
  );
}

export default App;
