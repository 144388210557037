import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles/Dashboard.module.css';
import { MainContent } from 'components/sidebar';
import { TextField, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Modal from '@mui/material/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LoadingOverlay from 'components/LoadingOverlay';
import { UploadIcon } from 'svg/CustomSVGIcon';
import {
  MultipleSelectDocument,
  SelectDocumentAnalysisBulkAction,
  SelectDocumentAnalysisStatus,
  SelectTags,
} from 'components/SelectField';
import { DateRangePicker } from 'rsuite';
import { DoneOutlined } from '@mui/icons-material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

import organizationAPI from 'api/api';

const RemoveModal = ({
  open,
  setOpen,
  setLoading,
  getDocumentsByOrganization,
  documentId,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleDelete = async (e, documentId) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteDocumentAnalayzeById(
        documentId
      );
      await getDocumentsByOrganization();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <h5>Remove</h5>
          <p>Are you sure you want to delete?</p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button
            onClick={(e) => {
              handleDelete(e, documentId);
              setOpen(false);
            }}
            className={styles.remove}
          >
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const DragAndDrop = ({ name, setName, onDrop }) => {
  const [files, setFiles] = useState([]);

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
      setFiles(acceptedFiles);
      setName(acceptedFiles[0].name);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
  });

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  return (
    <div className={styles.dropBox}>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          color: '#888',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <UploadIcon />
            <p>
              Click to attach your file, or drag and drop it here from your file
              browser. Please uplaod one file at  a time.
            </p>
          </>
        )}

        {files.length > 0 && (
          <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
            {files.length > 0 && (
              <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
                {files.map((file) => (
                  <li
                    key={file.name}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {name} - {(file.size / (1024 * 1024)).toFixed(2)} mb
                    </div>
                    <div>
                      <CloseOutlinedIcon onClick={() => removeFile(file)} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const DocumentDashboard = () => {
  const [loading, setLoading] = useState(false);

  const [canvasList, setCanvasList] = useState([]);
  const [user, setUser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [documentUploadOpen, setDocumentUploadOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [tags, setTags] = useState('');

  const [search, setSearch] = useState('');

  const [buttonDisable, setButtonDisable] = useState(true);

  const [files, setFiles] = useState([{}]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectDocumentType, setSelectDocumentType] = useState('All');
  const [bulkAction, setBulkAction] = useState('');
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [deleteDocumentId, setDeleteDocumentId] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const convertDate = (dateString) => {
    return moment(dateString).format('MMMM D, YYYY');
  };

  // const handleAnalysis = (e) => {
  //   e.preventDefault();
  //   navigate('/coach/documentanalysis/newanalysis');
  // };

  const handleView = (e, documentId) => {
    e.preventDefault();
    navigate(`/coach/documentanalysis/view/${documentId}`);
  };

  const handleDateChange = (date, type) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleOk = (data) => {
    setStartDate(data[0]);
    setEndDate(data[1]);
  };

  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    if (name && tags) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [name, tags]);

  const handleDrop = useCallback((acceptedFiles) => {
    // Do something with the dropped files, e.g. upload them to a server
    console.log(acceptedFiles);
    setFiles(acceptedFiles);
  }, []);

  const handleBulkAction = async (e) => {
    e.preventDefault();
    setLoading(true);
    const selectedDocuments = canvasList
      .filter((canvas) => canvas.isSelected)
      .map((canvas) => canvas._id);

    const data = {
      documentIds: selectedDocuments,
    };

    if (bulkAction === 'Student Review') {
      data.status = 'studentReview';
    } else if (bulkAction === 'Done') {
      data.status = 'done';
    }

    try {
      const response = await organizationAPI.bulkActionDocumentAnalyze(data);
      await getDocumentsByOrganization();
    } catch (error) {
      console.log(error);
    }

    navigate(0);
    setBulkAction('');
  };

  const getDocumentsByOrganization = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getDocumentAnalyzeByOrganization();
      const temp = response.data.reverse().map((item) => {
        if (item.status === 'coachReview') {
          return {
            ...item,
            status: 'Coach Review',
            isSelected: false,
          };
        } else if (item.status === 'aiReview') {
          return {
            ...item,
            status: 'AI Review',
            isSelected: false,
          };
        } else if (item.status === 'studentReview') {
          return {
            ...item,
            status: 'Student Review',
            isSelected: false,
          };
        } else if (item.status === 'done') {
          return {
            ...item,
            status: 'Done',
            isSelected: false,
          };
        } else if (item.status === 'error') {
          return {
            ...item,
            status: 'Error',
            isSelected: false,
          };
        } else if (item.status === 'pending') {
          return {
            ...item,
            status: 'Pending',
            isSelected: false,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      setCanvasList(temp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getDocumentsByOrganization();
  }, []);

  return (
    <MainContent user={user} active={'documentanalysis'}>
      {loading && <LoadingOverlay />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_view}>
          <div className={styles.modal_content}>
            <h2>Upload New Document</h2>
            <DragAndDrop name={name} setName={setName} onDrop={handleDrop} />
            <div>
              <FormControl
                className={styles.dropdown_section}
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Document Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              <FormControl
                className={styles.dropdown_section}
                sx={{
                  width: '100%',
                }}
              >
                <SelectTags
                  input={'TAGS'}
                  label={'TAGS'}
                  value={tags}
                  setValue={setTags}
                />
              </FormControl>
            </div>
            <button
              className={styles.start}
              disabled={buttonDisable}
              onClick={() => {
                setDocumentUploadOpen(true);
                setOpen(false);
              }}
            >
              Confirm and Upload
            </button>
          </div>
        </Box>
      </Modal>

      <RemoveModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        setLoading={setLoading}
        getDocumentsByOrganization={getDocumentsByOrganization}
        documentId={deleteDocumentId}
      />

      <h1>Welcome, {user && user.firstName + ' ' + user.lastName}</h1>
      <div className={styles.border}></div>
      <div className={styles.learner_header}>
        <h2>Documents</h2>
      </div>

      <div className={styles.input_fields}>
        <TextField
          id="outlined-basic"
          label="Search by keyword or tag"
          variant="outlined"
          className="search_input"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormControl
          className={styles.dropdown_section}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
        >
          <SelectDocumentAnalysisStatus
            value={selectDocumentType}
            setValue={setSelectDocumentType}
            input={'Filter by analysis status'}
            label={'Filter by analysis status'}
          />
        </FormControl>
        <FormControl
          className={styles.date_range}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
        >
          {/* <TextField
                id="date"
                label="Date Range"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
          <DateRangePicker
            placeholder="Date Range"
            format={'yyyy-MM-dd'}
            character="  to  "
            onChange={handleDateChange}
            onOk={handleOk}
            // ref={dateRangePickerRef}
            onClean={handleClean}
            style={{ minWidth: 200 }}
            className={styles.date_range_picker}
          />
        </FormControl>
        <FormControl
          className={styles.dropdown_section}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
        >
          <SelectDocumentAnalysisBulkAction
            value={bulkAction}
            setValue={setBulkAction}
            input={'Select Bulk Action'}
            label={'Select Bulk Action'}
          />
        </FormControl>
      </div>
      {canvasList.filter((canvas) => canvas.isSelected).length > 0 && (
        <div className={styles.submit_bar}>
          <span>
            {canvasList.filter((canvas) => canvas.isSelected).length} documents
            selected.
          </span>
          <button onClick={handleBulkAction}>
            <DoneOutlined /> Submit to NFTE
          </button>
        </div>
      )}

      <div className={styles.coach_roaster}>
        <div className={styles.learner_table}>
          <table className="styled_table">
            <thead className="table_headers">
              <tr>
                <th>
                  <Checkbox
                    {...label}
                    value={canvasList.every((canvas) => canvas.isSelected)}
                    onChange={(e) => {
                      const newCanvasList = canvasList.map((canvas) => {
                        return {
                          ...canvas,
                          isSelected: e.target.checked,
                        };
                      });
                      setCanvasList(newCanvasList);
                    }}
                  />
                  Name
                </th>
                <th>Uploaded by</th>
                <th>Uploaded on</th>

                {/* <th>Tags</th> */}
                <th
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Analysis Status
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {canvasList.length > 0 &&
                canvasList
                  .filter((item) => {
                    if (search) {
                      return (
                        item.answers[0]?.answer?.title
                          ?.toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.userId
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.userName
                          ?.toLowerCase()
                          .includes(search.toLowerCase())
                      );
                    }
                    return true;
                  })
                  .filter((item) => {
                    if (selectDocumentType !== 'All') {
                      return item.status === selectDocumentType;
                    } else {
                      return true;
                    }
                  })
                  .filter((item) => {
                    if (startDate && endDate) {
                      return (
                        moment(item.createdAt).isSameOrAfter(startDate) &&
                        moment(item.createdAt).isSameOrBefore(endDate)
                      );
                    }
                    return true;
                  })
                  .map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>
                          <Checkbox
                            checked={item.isSelected}
                            onChange={(e) => {
                              const newCanvasList = canvasList.map(
                                (canvas, i) => {
                                  if (i === index) {
                                    return {
                                      ...canvas,
                                      isSelected: e.target.checked,
                                    };
                                  }
                                  return canvas;
                                }
                              );
                              setCanvasList(newCanvasList);
                            }}
                          />
                          {item.answers[0]?.answer?.title
                            ? item.answers[0]?.answer?.title
                            : item.filename}
                        </td>
                        <td className={styles.data}>
                          {item.userName ? item.userName : item.userId}
                        </td>
                        <td className={styles.data}>
                          {convertDate(item.createdAt)}
                        </td>

                        {/* <td className={styles.tag_year}>
                        <span>TAG 01</span> <span>2024</span>
                      </td> */}
                        <td className={styles.data}>
                          {item.status === 'Student Review' && (
                            <p>
                              <FiberManualRecordOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                          {item.status === 'Pending' && (
                            <p>
                              <FiberManualRecordOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                          {item.status === 'AI Review' && (
                            <p>
                              <FiberManualRecordOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                          {item.status === 'Error' && (
                            <p className={styles.error}>
                              <ReportProblemOutlinedIcon /> {item.status}
                            </p>
                          )}
                          {item.status === 'Coach Review' && (
                            <p className={styles.progress}>
                              <PendingOutlinedIcon /> {item.status}
                            </p>
                          )}
                          {item.status === 'Done' && (
                            <p className={styles.complete}>
                              <CheckCircleOutlineOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                        </td>

                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <CloudDownloadOutlinedIcon />
                              <a
                                href={item.fileUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download
                              </a>
                            </div>
                            <div className={styles.resend}>
                              <VisibilityOutlinedIcon />
                              <button onClick={(e) => handleView(e, item._id)}>
                                View
                              </button>
                            </div>

                            <div className={styles.resend}>
                              <DeleteOutlineOutlinedIcon />
                              <button
                                onClick={(e) => {
                                  setDeleteDocumentId(item._id);
                                  setDeleteModalOpen(true);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {canvasList.length === 0 && (
            <div className={styles.empty_table}>
              <p>No submissions have been made yet.</p>
              <p onClick={handleOpen} className={styles.link}>
                Click here to make a new submission template.
              </p>
            </div>
          )}
        </div>
      </div>
    </MainContent>
  );
};

export default DocumentDashboard;
